.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  @media screen and (max-width: 800px) {
    padding-left: 10%;
    padding-right: 10%;
  }
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  padding-left: 30%;
  padding-right: 30%;
}

.modal {
  background: white;
  position: relative;
  margin: 10vh auto;
  border-radius: 5px;
  max-width: 80vw;
  padding: 1rem;
  margin-top: 10rem;
}

.modal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  width: 100%;
  height: 2.8rem;
  position: relative;
}

.modal-title {
  display: block;
  align-self: center;
}

.modal-close-button {
  display: block;
  position: absolute;
  align-self: flex-end;
  line-height: 1;
  cursor: pointer;
  border: none;
  width: 1.4rem;
  height: 1.4rem;
}

// button {
//   font-size: .9rem;
//   font-weight: 700;
//   border: none;
//   border-radius: 3px;
//   padding: .3rem 1rem;
//   margin-left: .5rem;
// }

.button-default {
  background: #247ba0;
  color: #fff;
}
