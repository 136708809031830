/* fonts */
@import url("https://fonts.google.com/specimen/Rubik?selection.family=Rubik:300,400,500,700");

/* * Styling code begins here */
html,
body {
  background: #fff;
  margin: 0 auto;
  font-family: "Rubik", sans-serif;
  box-sizing: border-box;
  color: #444444;
  line-height: 1.26;
  max-width: 100%;
  overflow-x: hidden;
  -moz-overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

//* Navigation Styling
.no-color {
  background: none !important;
}
.mock-logo {
  width: 110px;
  margin-top: -45px;
  margin-bottom: -45px;
}

.navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 10px 0;
  padding-top: 15px;
  padding-bottom: 20px;
  font-size: 0.9rem;
  margin: 0 auto;
  transition: top 0.3s;
  background-color: white;
}
.unfixed {
  position: absolute;
}

.hide-nav {
  display: none;
}
.show-nav {
  display: flex;
  z-index: 999999;
}

.navigation-container:active {
  background-color: #fff;
}

.header-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5.5%;
  width: 15%;
}
.main-nav {
  width: 35%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 0%;
  padding-right: 2%;
  margin-top: 5px;
}

.main-nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  font-size: 1rem;
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.33s ease;
}

.main-nav a:hover {
  text-decoration: underline;
}
#login-link {
  color: #0066cc;

  img {
    height: 15px;
    width: 11px;
    margin-right: 6px;
  }

  &:hover {
    color: #a33a00;
  }
}
#signup-link {
  border: none;
  background-color: #a33a00;
  border-radius: 5px;
  padding: 10px 13px;
  color: white;
  text-decoration: none;
  transition: 0.22s ease;
  &:hover {
    color: white;
    filter: brightness(110%);
  }
}
.nav-button {
  border: none;
  border-radius: 5px;
  padding: 5px 8px;
  color: #2e2f38;
  text-decoration: none;
  transition: 0.22s ease;
  &:hover {
    text-decoration: underline;
  }
}
@media screen and (max-width: 800px) {
  .main-nav {
    width: 50%;
  }
}

@media screen and (max-width: 500px) {
  .main-nav {
    width: 100%;
  }
  .main-nav a {
    display: none;
  }
  .navigation-container {
    nav {
      width: 100%;
    }
    nav a {
      display: none;
    }
  }
  .sign-up-btn {
    display: none;
  }
}
#noUnderline {
  text-decoration: none;
}

@media screen and (max-width: 500px) {
  #popupmap {
    display: none;
  }
}

//* Dashboard Bonus Features CTA
.kevin-motor {
  background-color: #f8fcff;
  background-color: #f1f9fd;
  border-top: 1px solid #ecf1f5;
}
.bonus-features-container {
  max-width: 900px;
  width: 100%;

  display: flex;
  align-items: center;

  height: 300px;

  @media screen and (max-width: 1100px) {
    width: 75%;
  }

  @media screen and (max-width: 900px) {
    height: 250px;
  }

  @media screen and (max-width: 500px) {
    height: 275px;
  }

  @media screen and (max-width: 400px) {
    height: 300px;
  }

  @media screen and (max-width: 350px) {
    height: 330px;
  }

  .bonus-features-CTA {
    width: 100%;
    margin-left: 20px;

    .bonus-features-title {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 0;
    }

    .bonus-features-description {
      margin-top: 10px;
      font-size: 1.2rem;
      line-height: 1.4;

      @media screen and (max-width: 1100px) {
        width: 550px;
      }

      @media screen and (max-width: 900px) {
        width: 500px;
      }

      @media screen and (max-width: 800px) {
        width: 400px;
      }

      @media screen and (max-width: 700px) {
        width: 350px;
      }

      @media screen and (max-width: 450px) {
        width: 300px;
      }

      @media screen and (max-width: 400px) {
        width: 280px;
      }
    }

    .sign-up-CTA {
      background-color: #0066cc;
      color: white;
      border: none;
      padding: 10px 30px;
      border-radius: 5px;
      font-size: 1.2rem;
      transition: 0.22s ease;

      cursor: pointer;
      &:focus {
        outline: 0;
      }

      &:hover {
        filter: brightness(130%);
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .bonus-features-container {
    flex-direction: column;

    .motorbike {
      width: 250px;
    }
    .bonus-features-CTA {
      margin-left: 0;
      .fonus-features-title {
        font-size: 1.2rem;
      }
      .bonus-features-description {
        font-size: 1rem;
      }
      .sign-up-CTA {
        font-size: 1.15rem;
      }
    }
  }
}

.dashboard-search-container {
  .dashboard-image {
    background-image: url(./components/pages/index/assets/wideSkylineHero.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 94vh;
    min-width: 1520px;
    min-height: 650px;
    margin-top: 70px;

    @media screen and (max-width: 500px) {
      min-height: 600px;
    }
  }

  .dashboard-image-header {
    margin-top: 22vh;
    margin-right: 650px;

    @media screen and (max-width: 850px) {
      margin-right: 750px;
    }

    @media screen and (max-width: 750px) {
      margin-right: 850px;
    }
    @media screen and (max-width: 700px) {
    }
  }
}

// * TOGGLE FUNCTION
.toggle-div {
  width: 97%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  #search-toggle {
    margin-top: 5px;
    display: flex;
    align-items: center;
    color: white;

    .switch {
      position: relative;
      display: inline-block;
      width: 41px;
      height: 23px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 17.42px;
      width: 17.42px;
      left: 2.7px;
      bottom: 2.7px;
      background-color: #0066cc;
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }

    input:checked + .slider {
      background-color: #ccc;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(17.42px);
      -ms-transform: translateX(17.42px);
      transform: translateX(17.42px);
    }

    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }

    .search-toggle-green {
      color: white !important;
    }
  }
}

// * SEARCH FUNCION
.dashboard-search-function {
  -webkit-transition: width 0.35s ease-in-out;
  transition: width 0.35s ease-in-out;
  width: 45%;
  animation: DashboardCTA;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.7s cubic-bezier(0.2, 0.8, 0.2, 1);

  @keyframes DashboardCTA {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  .dashboard-title {
    margin-top: -250px;
    width: 100%;
    font-weight: 500;
    font-size: 108px;
    line-height: 1;
    margin-bottom: 35px;

    @media screen and (max-width: 1440px) {
      font-size: 108px;
    }
    @media screen and (max-width: 850px) {
      font-size: 90px;
    }
  }
  .dashboard-title2 {
    margin-top: -150px;
    width: 100%;
    font-weight: 500;
    font-size: 85px;
    line-height: 1;
    margin-bottom: 35px;
    color: #444444;
    display: none;
    text-align: center;

    @media screen and (max-width: 414px) {
      margin-left: 9%;
    }

    @media screen and (max-width: 375px) {
      margin-left: 6%;
    }
    @media screen and (max-width: 360px) {
      margin-left: 5%;
    }
    @media screen and (max-width: 320px) {
      margin-left: 2%;
    }
  }

  .dashboard-function-container {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    width: 60%;
    @media screen and (max-width: 700px) {
      position: static;
    }
  }

  .location-div {
    margin: 0 auto;
  }

  .search-and-button {
    display: flex;
  }

  input {
    background-image: url("./components/pages/index/assets/search.svg");
    background-position: 10px 12px;
    background-repeat: no-repeat;
    background-color: #f7f9f9;
    padding: 13px 40px;
    font-size: 1.1rem;
    padding-left: 39px;
    width: 450px;
    border: 1px solid #b8bec4;
    border-radius: 5px;
    box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.07);

    @media screen and (max-width: 700px) {
      width: 300px;
    }

    &:focus {
      outline: none;
      filter: brightness(110%);
    }
  }

  .imageStyle {
    height: 17px;
    width: 20px;
  }

  // * SINGLE SEARCH CONTAINER
  .dashboard-single-search-container {
    margin-bottom: 7px;
    animation: DashboardCTR;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
    transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);

    @keyframes DashboardCTR {
      0% {
        opacity: 0;
        transform: translateY(-20px);
      }

      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    font-size: 1.1rem;
  }

  .dashboard-compare-search-container {
    animation: DashboardCTA;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
    transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);

    @keyframes DashboardCTA {
      0% {
        opacity: 0;
        transform: translateY(-20px);
      }

      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    input {
      border-radius: 5px;
      border: 1px solid #b8bec4;

      width: 478px;
    }
  }

  .compare-button {
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 0;
    margin-left: 10px;
    background-color: #0066cc;
    color: white;
    width: 70px;
    height: 50px;
    max-width: 400px;
    transition: 0.22s ease;
    box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.07);

    cursor: pointer;
    &:focus {
      outline: 0;
    }

    &:hover {
      filter: brightness(115%);
    }
  }

  .quiz-btn {
    border: none;
    border-radius: 5px;
    font-size: 1.7rem;
    font-weight: 500;
    padding: 0;
    margin-top: 20px;
    background-color: #0066cc;
    color: white;
    width: 38%;
    height: 65px;
    transition: 0.22s ease;
    box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.07);

    cursor: pointer;
    &:focus {
      outline: 0;
    }

    &:hover {
      filter: brightness(115%);
    }

    @media screen and (max-width: 800px) {
      margin-top: 8vh;
      width: 100%;
    }
  }
}

@media screen and (max-width: 700px) {
  .dashboard-search-container {
    flex-direction: column-reverse;
    margin-top: 70px;
    margin-bottom: 20px;
    margin-right: 4%;
    align-items: center;

    .toggle-div {
      width: 100%;
      justify-content: space-between;
    }

    .dashboard-image {
      width: 70%;
      margin-right: 0;
    }

    .dashboard-search-function {
      width: 100%;

      input {
        width: 50vw;
      }

      .dashboard-title {
        display: none;
      }
      .dashboard-title2 {
        display: flex;
        margin-right: 250px;
      }
    }
  }

  .dashboard-single-search-container {
    text-align: center;

    form {
      input:active {
        padding: 13px 40px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .dashboard-search-container {
    .dashboard-image {
      width: 100%;
    }

    .dashboard-search-function {
      .dashboard-title {
        margin-top: 10px;
        font-size: 9vw;
        margin-bottom: 40px;
      }
      .dashboard-title2 {
        margin-right: 370px;
      }
    }
  }
}

.dashboard-features-title {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  padding-bottom: 40px;

  .features-title {
    font-size: 1.6rem;
    font-weight: 500;
    text-align: center;

    @media screen and (max-width: 600px) {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 500px) {
  .dashboard-features-title {
    padding-top: 10px;
    margin-bottom: 0;
    text-align: center;
    .features-title {
      padding: 0;
    }
  }
}

//* Dashboard Features Styling
.kevin {
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 80px;
  background-color: #d2d7dc;
  border-bottom: 1px solid #ecf1f5;
  border-top: 1px solid #ecf1f5;
  height: 350px;

  @media screen and (max-width: 1100px) {
    height: 380px;
  }
}
.dashboard-features-container {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .feature-descriptions-container {
    width: 29%;
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: 1100px) {
      width: 25%;
    }
  }
  .feature-descriptions {
    line-height: 1.5;
    margin-left: 10px;

    p {
      width: 250px;
    }
  }
  .feature-images {
    width: 55px;
    height: 55px;
    margin-top: 5px;
    width: 100%;
  }
  .feature-title {
    margin-bottom: 5px;
    font-size: 1.2rem;
    font-weight: 500;
  }
  p {
    margin: 0;
  }
}

@media screen and (max-width: 1100px) {
  .kevin {
    padding-top: 15px;
  }

  .dashboard-features-container {
    justify-content: space-around;
  }
  .dashboard-features-title {
    padding-bottom: 10px;
  }
  .feature-descriptions-container {
    flex-direction: column;
  }
  .feature-images {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 900px) {
  .kevin {
    height: 330px;
    padding-top: 0;
  }
  .dashboard-features-container {
    padding-left: 5%;
    padding-right: 5%;

    .features-title {
      margin: 20px 0;
      padding-bottom: 0;
    }

    @media screen and (max-width: 700px) {
      padding-left: 0;
    }
    .feature-descriptions-container {
      flex-direction: column;
      height: 200px;
      p {
        font-size: 0.9rem;
      }
    }
    .feature-images {
      margin-bottom: 15px;
    }

    .feature-descriptions {
      p {
        width: 200px;
      }
      @media screen and (max-width: 700px) {
        p {
          width: 170px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .kevin {
    margin-top: 50px;
    padding-top: 5px;
    padding-bottom: 10px;
    height: auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .dashboard-features-container {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;

    .feature-descriptions-container {
      margin: 0 auto;
      margin-bottom: 50px;
      margin-top: 0;
      flex-direction: row;
      align-items: center;
      align-content: space-between;
      width: 85%;
      height: 100px;
    }
    .feature-descriptions {
      margin-left: 20px;

      p {
        width: 85%;
        margin-left: 30px;
      }
    }
    .feature-images {
      height: 100px;
      width: 45%;
    }
  }
}

@media screen and (max-width: 400px) {
  .feature-descriptions-container {
    .feature-descriptions {
      p {
        font-size: 0.8rem;
      }
    }
  }
}

//* Dashboard Data Metrics Styling
.dashboard-metrics-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  height: auto;

  @media screen and (max-width: 1000px) {
    margin-bottom: 80px;
  }

  .dashboard-metrics {
    margin: 0 auto;

    max-width: 1000px;
    padding: 0 3%;
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    @media screen and (max-width: 800px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
    }
  }
  .metrics-description-container {
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-end;
    height: 350px;

    @media screen and (max-width: 800px) {
      margin: 20px 0;
      width: 50%;
    }

    @media screen and (max-width: 500px) {
      margin-bottom: 20px;
    }

    .feature-images {
      margin-bottom: 10px;
    }
  }

  .metrics-title {
    font-size: 1.6rem;
    text-align: center;
    font-weight: 500;
    margin-top: 75px;
    margin-bottom: 0;

    @media screen and (max-width: 1000px) {
      margin-top: 50px;
    }

    @media screen and (max-width: 500px) {
      font-size: 1.4rem;
    }
  }
  .metrics-description {
    text-align: center;
    color: #596b77;
    line-height: 1.5;
  }
  .metrics-description-title {
    margin-bottom: 5px;
    margin-top: 10px;
    font-weight: 500;
    color: #2e2f38;
  }
}
@media screen and (max-width: 1000px) {
  .dashboard-metrics {
    padding: 3% 5%;
  }
}

@media screen and (max-width: 900px) {
  .dashboard-metrics {
    padding: 3% 0;
  }
}

@media screen and (max-width: 800px) {
  .dashboard-metrics-container {
    margin-bottom: 30px;

    .dashboard-metrics {
      .metrics-description-container {
        height: auto;
      }
    }
    .metrics-title {
      margin-bottom: 25px;
    }
  }
}
@media screen and (max-width: 500px) {
  .dashboard-metrics-container {
    margin-bottom: 0px;

    .dashboard-metrics {
      padding: 3% 5%;
      margin-top: 30px;
      flex-direction: column;

      .metrics-description-container {
        width: 100%;
        display: flex;
        height: auto;
        justify-content: center;
        margin-bottom: 60px;

        @media screen and (max-width: 500px) {
          margin-bottom: 20px;
        }

        p {
          padding-left: 25px;
          padding-right: 25px;
          width: 100%;
          text-align: center;
        }
      }
    }
    .metrics-title {
      margin-bottom: 0 !important;
      margin-top: 50px;
    }
  }
}

/* dashboard animations styling */

@media screen and (max-width: 500px) {
  [data-aos-delay] {
    transition-delay: 0s !important;
  }
}

// * Footer Styling
.footer-container {
  border-top: 1px solid rgba(203, 230, 252, 0.562);
  background-color: #e9e8e9;

  .footer {
    width: 100%;
    padding: 0 5%;
    justify-content: space-between;
  }

  .footer-style {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media screen and (max-width: 600px) {
      a {
        font-size: 0.8rem;
      }
    }

    @media screen and (max-width: 450px) {
      a {
        font-size: 0.6rem;
      }
    }
  }

  .footer1 {
    width: 50%;
    justify-content: flex-start;
    margin-top: 5px;

    @media screen and (max-width: 600px) {
      p {
        font-size: 0.8rem;
      }
    }

    @media screen and (max-width: 450px) {
      p {
        font-size: 0.6rem;
      }
    }

    p {
      color: #a33a00;
    }
  }

  .footer2 {
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-content: flex-end;
    margin-top: 5px;

    @media screen and (max-width: 800px) {
      width: 100%;
    }

    @media screen and (max-width: 600px) {
    }

    a {
      color: #a33a00;
    }
  }

  .footer3 {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
    }
  }
}
