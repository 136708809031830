//mobile first 

.signupForm{
    @media screen and (min-width:780px){
        flex-direction: row-reverse;
    }
}

.authForm{
    //container for left side of form
    display:flex; 
    margin-top: 19%; 


    

    @media screen and (min-width: 1000px) {
        margin-top:10%;
    }

    @media screen and (min-height: 640px) {
        margin-top: 15%; 

    }

    @media screen and (min-width: 2150px){
        margin-top: 6%; 
    }

    @media screen and (min-width: 600px){
        margin-top:16%; 
    }

    @media screen and (min-width:780px){
        margin-top: 12%;
        padding: 1%;
        align-items: flex-end;
        display:flex;
    }

    @media screen and (min-height:900px){
        margin-top:14%;
    }

    @media screen and (min-width: 2000px){
        margin-top:10%;
    }

    

    
    .mobileFormImage{
        img{
            width:100%; 
        }

        @media screen and (min-width: 780px){
            display:none;
        }
    }

    .form{
        margin: auto;
        width: 100%;
        

        .formTitle{
            text-align: center;
            font-weight: 500;
            margin:1% 0; 

            //samsung s3
            @media screen and (min-height: 640px) {
                font-weight: 500;
                 font-size: 1rem; 
                 letter-spacing: .1rem;
        
            }

            @media screen and (min-width: 1000px){
                font-size: 1.8rem; 
            }

        }

        @media screen and (min-width: 780px){
            width:43%;
        }
        
        @media screen and (min-width: 2350px){
            width: 30%; 
        }

        //container for oauth buttons
        .auth{ 

            width: 85%;
            margin: auto;
            margin-top: 5%;

            @media screen and (min-height: 640px) {
                margin: auto;
                margin-top: 2%;
        
            }

            @media screen and (min-width: 2400px){
                width: 70%;
            }

            @media screen and (min-width: 2900px){
                width: 64%; 
            }
            .oauthButton{
                
                border-radius: 3px; 
                display: flex;
                margin: 1vh 0;
                height: 7vh;
                align-items: center;
                cursor:pointer; 
                font-size: 1rem;

                // @media screen and (min-width: 800px){
                //     font-size: 1.2rem;
                // }

                @media screen and (min-width: 800px){
                    font-size: 1.2rem; 
                }

                @media screen and (min-height: 1300px){
                    height: 6vh; 
                }

                @media screen and (min-height: 1800px){
                    height: 4vh; 
                }

                @media screen and (min-height: 1500px){
                    height: 5vh; 
                }

                @media screen and (min-width: 2000px){
                    font-size: 1.8rem; 
                }
            }

            //styles for each button

            .GoogleButton{
                background-color: #4285F4;
                border-left: 1px solid #d8d8d8; 
                border-bottom: 1px solid #d8d8d8;

                .iconContainer{
                    background: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 1%;
                    font-weight: 600;
                    width: 12%;

                    @media screen and (min-width: 1000px){
                        width: 11%; 
                    }

                    @media screen and (min-width: 1400px){
                        width:9%; 
                    }

                    @media screen and (min-width: 2150px){
                        width: 8%; 
                    }
                }


                    img{
                        width:100%; 
                    }
                }

                .GoogleName{
                    margin-left:7%;
                    color:white;
                }
            }

            // .FacebookButton{
            //     background: #4368B2;

            //     .iconContainer{
            //         display: flex;
            //         justify-content: center;
            //         align-items: center;
            //         margin: 2%;
            //         width: 13%;
            //         margin-left: 1%;
            //         @media screen and (min-width: 400px){
            //             width:11%;
            //         }

            //         @media screen and (min-width:1000px){
            //             width:10%;
            //         }
                    
                    
            //         @media screen and (min-width: 1040px) {
                   
            //             margin-left: .4vw;
            //             margin-top: .7vh;
                
            //     }
            //     }

            //     .FacebookName{
            //         margin-left:5.5%;
            //         color:white;
            //     }

               
            // }

            .LinkedinButton{
                background: #0077B5;
                color: white;

                .iconContainer{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 2%;
                    width: 13%;
                    margin-left: 2%;

                    img{
                        width:100%; 
                    }
                    @media screen and (min-width: 400px){
                        width: 11%;
                    }

                    @media screen and (min-width: 1200px){
                        width:10%;
                    }

                    @media screen and (min-width: 1350px){
                        width:9%;
                    }

                    @media screen and (min-width: 2150px){
                        width: 8%; 
                    }
                    @media screen and (min-width: 1040px){
                        margin-top: .6vh;
                        margin-left: .5vw;
                    }
                }
                .LinkedinName{
                    margin-left:3.5%;
                    color:white;
                }
            }

            //media queries

            @media screen and (min-width:400px) {
                //margin-top:0;
                width:100%;
            }

            @media screen and (min-width: 500px){
                width: 70%;
            }
            @media screen and (min-width:550px){
                width:60%;

            }

            @media screen and (min-width:700px){
                width:48%;
            }
            //ipads
            @media screen and (min-width: 768px) and (min-height: 1000px){
                width:80%;
            }

            @media screen and (min-width:840px){
                width: 40%;
            }
            @media screen and (min-width:1000px){
                width: 42%; 
            }

            @media screen and (min-width:1200px){
                width:40%;
            }

            @media screen and (min-width:1500px){
                width:35%; 
            }
            @media screen and (min-width: 1650px){
               // width:26%;
            }

            @media screen and (min-width: 2000px){
                //width: 35vh; 
                font-size: 1.4rem;

                .GoogleButton{
                    .GoogleName{
                        margin-left: 3vw;
                    }
                }

                .LinkedinButton{
                    .LinkedinName{
                        margin-left: 3vw;
                    }
                }

                .FacebookButton{
                    .FacebookName{
                        margin-left:3vw;
                    }
                }
            }

        }//end of auth container

        //container for every thing below the oauth buttons
        .bottomPortion{
            width:100%;
            margin:auto;
            

            //container for lines with text 
            .centerText{
                display:flex;
                align-items: center;
                width:100%;
                justify-content: center;
                margin-top:6%;

                @media screen and (min-height: 640px) {
                    margin-top: 2%;
                }
            
                @media screen and (min-width: 700px){
                    font-size:1rem; 
                }
                //text "or with email"
                .center{
                    margin: 0 2%;
                    font-size: 80%;

                    @media screen and (min-width: 2000px){
                        font-size: 1.2rem; 
                    }
                }

                //gray lines
                .line{
                height: .5px;
                border: .4px solid  rgb(201, 201, 201);
                width: 16%;
                }
            }//end of centertext

            .fields{
                width: 90%;
                margin: auto;

                //error messages
                .formError{
                    font-size: .7rem;
                    color: #fc3966;
                    text-align: center;
                    margin: 1%;

                    @media screen and (min-width: 1200px){
                        font-size: 1.2rem; 
                    }
                }

                .pp{
                    display: flex;
                    align-items: center;
                    color:#665858;
                    justify-content: center;
                    font-size: .9rem;
                    margin-top:2%;

                    @media screen and (min-width: 700px){
                        font-size: 1.2rem; 
                    }

                    @media screen and (min-width: 2000px){
                        font-size: 1.4rem; 
                    }
                    p{
                        margin:0;
                        .ppText{
                            margin: 1.5vw;
                            cursor: pointer;
                            color: #3999fc; 

                            @media screen and (min-width: 700px){
                                margin:1vw; 
                            }

                            @media screen and (min-width: 1000px){
                                margin: .5vw; 
                            }
                        }

                    }
                  }

                
                .field{

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    width: 100%;
                    border: 1px solid #d6d6d6;
                    border-radius: 5px;
                    padding: 0% 3%;
                    margin-top:2%;

                    &:focus-within{
                        outline-style: none;
                        border-color: dodgerBlue;
                        box-shadow: 0 0 8px 0 dodgerblue;
                    }
                    .fieldIcon{
                        width:7%;


                        svg{
                            width:100%;
                        }
                    }
                    .username, .password{
                        padding: 3.5% 2%; 
                        width:100%;
                        margin-top:1%;
                        border:none;

                        &:focus{
                            outline-style: none;
                        }
    
                        @media screen and (min-height: 640px) {
                            padding: 2.5% 2%;
                            width: 100%;
                            margin-top: 1%;
                    
                        }

                        @media screen and (min-width: 700px){
                            font-size: 1rem;
                        }

                        @media screen and (min-width: 2000px){
                            font-size: 1.2rem; 
                        }
                }
                   
                }
                .form-error {
                    color:red;
                    text-align:center;
                    margin-bottom:0;
                }
                .formButton{
                    width: 100%;
                    /* margin: auto; */
                    border: 1px solid #3999FC;
                    border-radius: 4px;
                    background: #3999FC;
                    color: white;
                    padding: 3% 2%;
                    margin-top: 5%;
                    font-size: .8rem;
                    cursor: pointer;


                    &:hover{
                        color: #3999FC;
                        background:white;
                    }

                    @media screen and (min-height: 640px) {
                        margin-top: 3%;
                        font-size: .9rem;
                
                    }

                    @media screen and (min-width: 2000px){
                        font-size:1.4rem; 
                    }

                }

                .question{
                    font-size: 58%;
                    color: #665858;
                    margin: 1%;

                    @media screen and (min-width: 360px){
                        font-size: 75%;
                    }

                    @media screen and (min-width: 850px){
                        font-size:100%; 
                    }

                    div{
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: center;
                        width: 100%;

                    
                    }

                        .link-authform{
                            margin: 1%;
                            text-decoration: none;
                            color: #3999FC;
                            cursor: pointer;
                        }
                    
                }
            }

           

        }

    }//end of form container

    
    .authFormPhoto{
        width: 50%; 
        display:none;

            img{
                width:100%;
            }

            @media screen and (min-width:780px){
                display:block;
                
                
            }
        }




