//mobile first

.aboutUsHero {
  background-image: url("/MtT_Hero Image.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  height: 400px;
  display: flex;

  @media screen and (min-width: 1200px) {
    height: 500px;
  }

  .aboutUsContainer {
    margin: 0 auto;

    @media screen and (min-width: 900px) {
      margin-left: 100px;
    }

    @media screen and (min-width: 1200px) {
      margin-left: 11%;
    }

    .aboutUsH1Div {
      position: sticky;
      top: 100px;
      max-width: 350px;

      @media screen and (min-width: 1200px) {
        top: 135px;
        max-width: 450px;
      }

      .aboutUsH1 {
        font-weight: 500;
        font-size: 4rem;
        line-height: 100%;
        color: white;
        text-align: left;

        @media screen and (min-width: 1200px) {
          font-size: 6rem;
        }
      }

      .aboutUsADiv {
        display: flex;
        flex-direction: column;

        .labslink {
          color: white;
          line-height: 80%;
          padding-bottom: 20px;
          text-decoration: none;
          font-family: "Rubik", sans-serif;
          font-style: normal;
          font-weight: normal;

          @media screen and (min-width: 1200px) {
            font-size: 1.5rem;
          }
        }

        .member-nav {
          a {
            padding-bottom: 20px;
            text-decoration: none;
            font-family: "Rubik", sans-serif;
            font-style: normal;
            font-weight: normal;

            @media screen and (min-width: 1200px) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}

.aboutUsSection {
  .sectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 5%;

    @media screen and (min-width: 600px) {
      margin: 0 15%;
    }

    @media screen and (min-width: 900px) {
      margin: 0 10%;
    }

    .aboutUsH2Div {
      text-align: left;

      .aboutUsH2 {
        font-weight: 500;
        font-size: 2.2rem;
        line-height: 100%;

        @media screen and (min-width: 1200px) {
          font-size: 2.3rem;
        }

        @media screen and (min-width: 2000px) {
          font-size: 3.5rem;
        }

        span {
          display: block;
          font-size: smaller;
          font-weight: 100;
        }
      }
    }

    .cardContainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;

      @media screen and (min-width: 900px) {
        flex-direction: row;
        // justify-content: space-around;
      }

      .card {
        margin-bottom: 20px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (min-width: 900px) {
          width: 48%;
          // margin-right: 1%;
          padding-right: 20px;
        }

        @media screen and (min-width: 1200px) {
          width: 23%;
          margin-right: 1.5%;
        }

        @media screen and (min-width: 2000px) {
          width: 23%;
          margin-right: 1%;
        }

        .member-info {
          margin-bottom: 5%;

          .aboutUsImgDiv {
            width: 100%;

            @media screen and (min-width: 900px) {
              padding-bottom: 100%;
              position: relative;
            }

            .aboutUsImg {
              border-radius: 5px;
              width: 100%;
              height: 100%;
              object-fit: cover;

              @media screen and (min-width: 900px) {
                width: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
              }
            }
          }

          h3 {
            margin-bottom: 0;

            @media screen and (min-width: 2000px) {
              font-size: 2rem;
            }
          }

          .memberRole {
            margin: 0;

            @media screen and (min-width: 2000px) {
              font-size: 1.3rem;
            }
          }

          .socialIcon {
            margin: 3px;
          }

          .aboutP {
            font-size: smaller;

            @media screen and (min-width: 2000px) {
              font-size: 1.3rem;
            }
          }
        }

        .member-portfolio {
          border-radius: 5px;
          // min-height: 50px;
          font-size: 0.7rem;
          // padding: 2.5% 1%;
          text-align: center;
          border: 1px solid #b8babf;
          color: #5e6066;
          cursor: pointer;
          width: 100%;

          &:hover {
            color: white;
            background: #b8babf;
          }

          @media screen and (min-width: 1200px) {
            font-size: 1rem;
          }

          @media screen and (min-width: 2000px) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
