//* Navigation Styling

.mock-logo {
  width: 110px;
  margin-top: -45px;
  margin-bottom: -45px;
}

.navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px 0;
  padding-top: 15px;
  padding-bottom: 20px;
  font-size: 0.9rem;
  margin: 0 auto;
  transition: top 0.3s;
  background-color: white;
  z-index: 1000;
}
.unfixed {
  position: absolute;
}

.hide-nav {
  display: none;
}
.show-nav {
  display: flex;
  z-index: 999999;
}

.navigation-container:active {
  background-color: #fff;
}

.header-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5.5%;
  width: 15%;
}
.main-nav {
  width: 35%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 0%;
  padding-right: 2%;
  margin-top: 5px;
}

.main-nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  font-size: 1rem;
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.33s ease;
}

.main-nav a:hover {
  text-decoration: underline;
}
.login-link {
  color: #a33a00;

  img {
    height: 15px;
    width: 11px;
    margin-right: 6px;
  }

  &:hover {
    color: #a33a00;
  }
}

.nav-button {
  border: none;
  border-radius: 5px;
  padding: 5px 8px;
  color: #2e2f38;
  text-decoration: none;
  transition: 0.22s ease;
  &:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: 800px) {
  .main-nav {
    width: 50%;
  }
}

@media screen and (max-width: 500px) {
  .main-nav {
    width: 100%;
  }
  .main-nav a {
    display: none;
  }
  .navigation-container {
    nav {
      width: 100%;
    }
    nav a {
      display: none;
    }
  }
  .sign-up-btn {
    display: none;
  }
}

/* Dropdown menu */
@media screen and (max-width: 6000px) {
  .dropdownContainer {
    display: none;
  }
  .dropdown-content a {
    display: none;
  }
}
@media screen and (max-width: 1440px) {
  .dropdownContainer {
    display: none;
  }
  .dropdown-content a {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  #popupmap {
    display: none;
  }
  .dropdownContainer {
    display: none;
  }
  .dropdown-content a {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .dropdownContainer-loggedin .dropdown-content-loggedin .redundant {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .dropdownContainer {
    display: flex;
  }
  .dropbtn {
    background-color: #fcfcfb;
    color: #0066cc;
    font-weight: 600;
    padding: 10px 20px;
    font-size: 16px;
    border: 1.4px solid #0066cc;
    border-radius: 5px;
    margin-right: 30px;

    &:hover {
      transition: 0.33s ease;
    }
  }

  .dropdown {
    position: relative;
    float: right;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 150px;
    z-index: 1;
  }

  .dropdown-content a {
    color: #2e2f38;
    padding: 15px 15px;
    text-decoration: none;
    display: block;
    font-size: 1.1rem;
  }

  .dropdown-content a:hover {
    color: #fcfcfb;
    background-color: #0066cc;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    color: #fcfcfb;
    background-color: #0066cc;
  }
}
