.city-overview-cards-container {
  display: grid;
  grid-gap: 1.4rem;
  grid-template-rows: 1fr;
  grid-template-columns: auto;
  // Overview cards in mobile and tablet are stacked vertically
  grid-auto-flow: row;

  @media screen and (min-width: 1000px) {
    // In desktop they take advantage of horizontal space
    grid-auto-flow: column;
  }

  //One whole card
  .city-overview-card {
    height: 100%;
    // The overview portion of the card
    .city-overview-container {
      background-color: white;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 0 10px;
      margin-bottom: 0;
      width: 100%;
      .city-overview-border {
        margin-bottom: 0 !important;
        font-size: 1.3rem;
        width: 100%;
        display: flex;
        p {
          margin-left: 1.4rem;
        }
      }
      .city-overview-content {
        display: flex;
        // Card content starts as a column
        flex-direction: column;
        padding: 15px 3%;
        margin-bottom: 0;
        justify-content: space-between;
        // When above 600px, tablet, the content inside each card breaks to a row
        @media screen and (min-width: 600px) {
          flex-direction: row;
        }
        // When the cards lay out horizontally in desktop view, the inner content goes back to a column
        @media screen and (min-width: 1000px) {
          flex-direction: column;
        }
        img {
          height: 20px;
          margin-right: 15px;
        }
        .city-overview-container-one {
          display: flex;
          width: 100%;
          align-items: center;
          .overview-title-container-one {
            width: 60%;
            display: flex;
            flex-direction: column;
            .overview-title {
              margin: 10px 0;
              color: grey;
              display: flex;
              align-items: center;
              font-size: 1.1rem;
              cursor: pointer;
              transition: color 500ms;
              &:hover {
                color: #0066cc;
              }
            }
          }
          .overview-stats-container-one {
            width: 40%;
            font-size: 1.1rem;
            display: flex;
            flex-direction: column;

            .overview-stats {
              width: 100%;
              margin: 10px 0;
            }
          }
        }
        .city-overview-container-two {
          display: flex;
          width: 100%;
          justify-content: space-between;

          .overview-title-container-two {
            width: 60%;
            display: flex;
            flex-direction: column;
            .overview-title {
              margin: 10px 0;
              color: grey;
              display: flex;
              align-items: center;
              font-size: 1.1rem;
              cursor: pointer;
              transition: color 500ms;
              &:hover {
                color: #0066cc;
              }
            }
          }
          .overview-stats-container-two {
            width: 40%;
            font-size: 1.1rem;
            display: flex;
            flex-direction: column;
            .overview-stats {
              width: 100%;
              margin: 10px 0;
            }
          }
        }
      }
    }

    // When there is only one card in desktop it will stretch horizontally, and so the content will break into a row as well
    &:only-child .city-overview-container .city-overview-content {
      @media screen and (min-width: 1000px) {
        flex-direction: row;
      }
    }

    // Recommended similar city portion of the card
    .recommendation-grid {
      background-color: white;
      border-radius: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      width: 100%;

      .recommendation-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }

      .recommendation-title {
        display: flex;
        align-items: center;
        color: grey;
        font-size: 14px;
        margin: 0 auto;
        border: 1px solid #ecf1f5;
        width: 50%;
        justify-content: center;
        text-align: center;
        padding: 10px;
      }
      .recommendation {
        cursor: pointer;
        border: 1px solid #ecf1f5;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 50%;
        padding: 10px;

        .recommendation-subtitle {
          color: grey;
          width: 100%;
          font-size: 14px;
          text-align: center;
          margin-bottom: 3px;
          margin-top: 0;
        }

        img {
          height: 17px;
          margin-right: 5px;
        }
        .recommendation-cities {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          white-space: nowrap;
        }
        &:hover {
          background-color: #f6f9fc;
          border: 1px solid #e9e8e9;
        }
      }
    }
    &:only-child {
      max-width: none;
      .recommendation-grid {
        background-color: white;
        border-radius: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        width: 100%;

        .recommendation-container {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }
        .recommendation-title {
          display: flex;
          align-items: center;
          color: grey;
          font-size: 14px;
          margin: 0 auto;
          border: 1px solid #ecf1f5;
          width: 50%;
          justify-content: center;
          text-align: center;
          padding: 10px;
        }
        .recommendation {
          cursor: pointer;
          border: 1px solid #ecf1f5;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: 50%;
          padding: 10px;

          .recommendation-subtitle {
            color: grey;
            width: 100%;
            font-size: 14px;
            text-align: center;
            margin-bottom: 3px;
            margin-top: 0;
          }

          img {
            height: 17px;
            margin-right: 5px;
          }
          .recommendation-cities {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
          }
          &:hover {
            background-color: #f6f9fc;
            border: 1px solid #e9e8e9;
          }
        }
      }
    }
  }
}
