.profile-body {
  background-color: #e9e8e9;
  margin-top: 4.5rem;
  @media screen and (max-width: 600px) {
    margin-top: 4.9rem;
  }
}

.profile-page {
  margin: auto;
  min-height: 86.1vh;
  display: flex;
  background-color: #e9e8e9;
  width: 75%;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  .profile-slides {
    margin: 0 auto;
    margin-top: 4rem;
    width: 75%;
  }

  .recommendation-grid {
    display: none;
  }

  .update-favorites {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    button {
      border: none;
      margin: 10px auto;
      width: 130px;
      border-radius: 5px;
      font-size: 1.1rem;
      font-weight: 500;
      padding: 5px;
      background-color: #a33a00;
      color: white;
      max-width: 400px;
      transition: 0.22s ease;
      box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.07);
      cursor: pointer;
      &:focus {
        outline: 0;
      }
      &:hover {
        filter: brightness(115%);
      }
      cursor: pointer;
    }

    h1 {
      font-size: 32px;
      margin-bottom: 32px;
      font-weight: 400;
      color: #5e6066;
      text-align: center;
    }

    .favorites-header-hidden {
      display: none;
    }

    h2 {
      font-size: 32px;
      margin-bottom: 32px;
      text-align: center;
      font-weight: 400;
      color: #32373d;
    }

    .single-fav {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 1.4rem;
      width: 100%;

      @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
        margin: 0;
      }

      .fav-title {
        white-space: nowrap;
        font-size: 20px;
      }
      .go-btn {
        position: absolute;
        right: 2.8rem;
        display: block;
        height: 2.8rem;
        border: none;
        border-radius: 5px;
        font-size: 1.1rem;
        font-weight: 500;
        padding: 0;
        background-color: white;
        border: 1px solid #0066cc;
        color: #0066cc;
        transition: 0.22s ease;
        box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.07);
        margin-right: 1.4rem;

        @media screen and (max-width: 800px) {
          position: relative;
        }

        cursor: pointer;
        &:focus {
          outline: 0;
        }

        &:hover {
          background-color: #0066cc;
          color: white;
        }
      }
    }

    .city-overview-container {
      width: 70%;

      background-color: white;
      padding: 0 10px;
      margin: 30px auto;

      @media screen and (max-width: 800px) {
        width: 100%;
      }

      .city-overview-border {
        margin-bottom: 0 !important;
        padding-left: 2%;
        font-size: 1.3rem;

        width: 100%;
        display: flex;
      }

      .city-info-container {
        margin-bottom: 0;
        padding: 15px 7%;
        padding-top: 0;
        display: flex;
        justify-content: space-between;

        img {
          height: 20px;
          margin-right: 15px;
        }
        @media screen and (max-width: 800px) {
          flex-wrap: wrap;
          padding: 15px 3%;
          margin-bottom: 0;
        }

        .city-overview-container-one {
          width: 45%;
          display: flex;
          align-items: center;

          @media screen and (max-width: 800px) {
            width: 100%;
          }

          .overview-title-container-one {
            width: 60%;
            display: flex;
            flex-direction: column;

            .overview-title {
              margin: 10px 0;
              color: grey;
              display: flex;
              font-size: 1.1rem;
            }
          }
          .overview-stats-container-one {
            width: 45%;
            font-size: 1.1rem;
            display: flex;
            flex-direction: column;

            .overview-stats {
              width: 100%;
              margin: 10px 0;
            }
          }
        }
        .city-overview-container-two {
          width: 40%;
          display: flex;
          @media screen and (max-width: 800px) {
            width: 100%;
            justify-content: space-between;
          }

          .overview-title-container-two {
            width: 70%;
            display: flex;
            flex-direction: column;

            .overview-title {
              margin: 10px 0;
              color: grey;
              display: flex;
              font-size: 1.1rem;
            }
            @media screen and (max-width: 800px) {
              width: 60%;
            }
          }
          .overview-stats-container-two {
            width: 27%;
            font-size: 1.1rem;
            display: flex;
            flex-direction: column;
            .overview-stats {
              width: 100%;
              margin: 10px 0;
            }
            @media screen and (max-width: 800px) {
              width: 40%;
            }
          }
        }
      }
    }
  }
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  margin-top: 110px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  @media screen and (max-width: 1400px) {
    width: 80%;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
  }

  .edit-profile-closed {
    display: none;
  }

  .profile-contents {
    line-height: 126%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .avatar-tab {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 45%;
    height: 35vh;

    background-color: white;

    @media screen and (max-width: 1400px) {
      padding: 15px;
    }

    @media screen and (max-width: 900px) {
      padding: 10px;
    }

    h2 {
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 20%;
    }
    .username {
      margin-bottom: 15px;
    }

    img {
      width: 150px;
      height: 150px;
      border-radius: 100%;
      box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.09);

      @media screen and (max-width: 900px) {
        width: 130px;
        height: 130px;
      }
    }
  }

  .info {
    height: 35vh;
    background-color: white;
    box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.09);
    width: 45%;
    text-align: center;
    padding: 15px;
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    justify-content: space-around;

    button {
      margin: 0 auto;
    }
  }

  h1 {
    font-size: 32px;
    margin: 10px auto;
    margin-bottom: 20px;
    font-weight: 500;
    color: #5e6066;
  }

  .name-tab,
  .email-tab,
  .city-tab,
  .avatar-tab {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    input {
      height: 20%;
      width: 200px;
      margin-right: 16px;
      background-color: #f7f9f9;
      border: 1px solid #b8babf;
      border-radius: 10px;
      height: 30px;
      font-size: 16px;
      color: #84868c;
      padding: 14px;

      @media screen and (max-width: 1400px) {
        width: 200px;
        font-size: 1rem;
        padding: 5px;
        height: 30px;
      }
      @media screen and (max-width: 900px) {
        width: 170px;
        margin-right: 5px;
      }
    }

    p {
      font-size: 18px;
      font-weight: 500;
      color: #5e6066;
      text-align: center;
      @media screen and (max-width: 900px) {
        margin-bottom: 5px;
        font-size: 1rem;
      }
    }

    .image-input {
      padding: 5px;
      width: 80%;
    }

    button {
      border: none;
      margin-top: 10px;
      width: 130px;
      border-radius: 5px;
      font-size: 1.1rem;
      font-weight: 500;
      padding: 5px;
      background-color: #a33a00;
      color: white;
      max-width: 400px;
      transition: 0.22s ease;
      box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.07);
      cursor: pointer;
      &:focus {
        outline: 0;
      }
      &:hover {
        filter: brightness(115%);
      }
      cursor: pointer;
      @media screen and (max-width: 900px) {
        width: 120px;
        font-size: 1rem;
      }
    }
    h2 {
      color: #5e6066;
      margin: 0;
      @media screen and (max-width: 900px) {
        margin-bottom: 5px;
        margin-top: 1px;
      }
    }
  }
  .name-tab {
    h2 {
      font-size: 24px;
      font-weight: 400;
      @media screen and (max-width: 900px) {
        font-size: 1.2rem;
      }
    }

    input {
      width: 150px;
      @media screen and (max-width: 1400px) {
        width: 100px;
      }
      @media screen and (max-width: 900px) {
        width: 80px;
      }
    }
  }

  .email-tab {
    h2 {
      font-size: 20px;
      font-weight: 400;
      @media screen and (max-width: 900px) {
        font-size: 1.2rem;
      }
    }
  }

  .city-tab {
    h2 {
      font-size: 20px;
      font-weight: 400;
      @media screen and (max-width: 900px) {
        font-size: 1.2rem;
      }
    }

    .edit-state {
      width: 64px;
      padding: 12px;
    }
  }

  .edit-location-btn.open {
    margin-top: 10%;
  }

  .edit-name.closed,
  .save-name-btn.closed,
  .edit-name-btn.open,
  .edit-email.closed,
  .save-email-btn.closed,
  .edit-email-btn.open,
  .edit-location.open,
  .save-location-btn.open,
  .edit-location-btn.closed,
  .user-name.open,
  .user-email.open,
  .user-location.closed,
  .edit-image.closed,
  .save-image-btn.closed,
  .edit-image-btn.open {
    display: none;
  }
}

.city-overview-container-small {
  display: flex;
  justify-content: space-between;
}

// ONE CITY SELECTED STYLING
.city-overview-container-medium {
  background-color: white;
}

.scores-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
}

.stats-div-readjusted {
  display: flex;
  width: 100%;
}

.scores-divided {
  width: 50%;
}

.main-contain {
  display: flex;
}

.stats-style {
  display: flex;
  align-items: center;
}

.stats-div {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.scores-div {
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.weather-style {
  display: flex;
  justify-content: center;
}

.stats-img {
  padding-left: 25px;
}

.stats-par {
  padding-left: 20px;
}

.historical-weather {
  text-align: center;
}

.icon-score-contain {
  display: flex;
  height: 175px;
}

.scores-contain {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.title-contain {
  text-align: center;
}

.title-contain h4 {
  margin: 0;
  font-size: 20px;
}

.title-contain p {
  margin: 0;
}

.icon-contain {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-contain img {
  width: 55%;
}

.number-contain {
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.number-contain h5 {
  margin: 0;
}

.number-contain p {
  margin: 0;
}

.number-style25 {
  font-size: 70px;
  color: #fa6501;
}

.number-style50 {
  font-size: 70px;
  color: #12d000;
}

.number-style75 {
  font-size: 70px;
  color: #0062f4;
}

.number-style100 {
  font-size: 70px;
  color: #1c00ee;
}

.number-style25-2 {
  font-size: 50px;
  color: #fa6501;
}

.number-style50-2 {
  font-size: 50px;
  color: #12d000;
}

.number-style75-2 {
  font-size: 50px;
  color: #0062f4;
}

.number-style100-2 {
  font-size: 50px;
  color: #1c00ee;
}

.number-style25-3 {
  font-size: 50px;
  color: #fa6501;
}

.number-style50-3 {
  font-size: 50px;
  color: #12d000;
}

.number-style75-3 {
  font-size: 50px;
  color: #0062f4;
}

.number-style100-3 {
  font-size: 50px;
  color: #1c00ee;
}

.quality-button {
  width: 200px;
  height: 50px;
  left: 30px;
  top: 78px;
  color: white;
  font-family: Rubik;
  font-style: normal;

  font-size: 18px;
  line-height: 134.19%;
  border-radius: 7px;
}

.button-div {
  display: flex;

  padding-left: 30px;
}

.sustain-number {
  font-size: 70px;
}

.safety-number {
  font-size: 70px;
}

.access-number {
  font-size: 70px;
}

// TWO CITIES SELECTED STYLING
.city-overview-container-medium2 {
  width: 48%;
  background-color: white;
}

.main-contain2 {
  display: flex;
  flex-direction: column;
}

.scores-div2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.stats-style2 {
  display: flex;
  align-items: center;
}

.stats-style2 p {
  font-size: 18px;
}

.scores-contain2 {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 150px;
}

.title-contain2 h4 {
  margin: 0;
  font-size: 16px;
}

.title-contain2 p {
  margin: 0;
  font-size: 14px;
}

.walk-number2 {
  font-size: 50px;
  color: #a74e12;
}

.transit-number2 {
  font-size: 50px;
  color: #13019a;
}

.bike-number2 {
  font-size: 50px;
  color: #13670b;
}

.sustain-number2 {
  font-size: 50px;
}

.safety-number2 {
  font-size: 50px;
}

.access-number2 {
  font-size: 50px;
}

.weather-style2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-div2 {
  display: flex;
  width: 50%;

  align-items: center;
}

.title-container2 {
  width: 100%;
}

.avg-temp-container2 {
  padding: 10px;
  border-radius: 5px;
  width: 75%;

  margin-bottom: 30px;
  background-color: white;
}

// THREE CITIES SELECTED STYLING
.city-overview-container-medium3 {
  width: 30%;
  background-color: white;
}

.title-contain3 h4 {
  margin: 0;
  font-size: 14px;
}

.title-contain3 p {
  margin: 0;
  font-size: 12px;
}

.scores-div3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-top: 20px;
}

.scores-contain3 {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 125px;
}

.avg-temp-container3 {
  padding: 10px;
  border-radius: 5px;

  margin-bottom: 30px;
  background-color: white;
}

.city-overview-container3 {
  width: 100%;

  background-color: white;
  padding: 0 5px;
  margin: 5px auto;

  .city-overview-border3 {
    margin-bottom: 0 !important;
    padding-left: 2%;
    font-size: 1.3rem;

    width: 100%;
    display: flex;
  }
}

.title-contain3 {
  width: 100%;
  text-align: center;
}

.button-contain3 {
  display: flex;
  justify-content: center;
}

.icon-contain .bike-image {
  width: 70%;
}

.button-weather-contain {
  width: 100%;
  display: flex;
}

// 500PX MOBILE STYLING FOR 1 CITY SELECTED
@media screen and (max-width: 500px) {
  .stats-style {
    p {
      font-size: 1rem;
    }
  }

  .stats-div {
    display: flex;
    height: 250px;
    width: 100%;
  }

  .button-div {
    display: flex;
    justify-content: center;
  }

  .city-overview-border {
    display: flex;
    justify-content: center;
  }

  .main-contain {
    display: flex;
    flex-direction: column;
  }

  .stats-div-readjusted {
    width: 100%;
  }

  .scores-div {
    width: 100%;
  }

  .scores-container {
    display: flex;
    flex-wrap: wrap;
  }

  .scores-contain {
    h4 {
      font-size: 1rem;
    }
    p {
      font-size: 0.9rem;
    }
  }

  .scores-contain {
    width: 48%;
  }
} // ends mobile styling for 1 CITY SELECTED

// 500PX MOBILE STYLING FOR 2 CITIES SELECTED
@media screen and (max-width: 500px) {
  .button-weather-contain {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .title-container2 {
    p {
      font-size: 1rem;
    }
  }

  .button-div2 {
    width: 100%;
  }

  .stats-style2 {
    width: 90%;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .scores-div2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .scores-contain2 {
    width: 100%;
  }
} // ends media for 2 cities selected

// 500PX MOBILE STYLING FOR 3 CITIES SELECTED
@media screen and (max-width: 500px) {
  .city-overview-border3 {
    height: 75px;

    .title-contain3 {
      font-size: 1rem;
    }
  }

  .avg-temp-container {
    display: none;
  }
  .avg-temp-container2 {
    display: none;
  }
  .avg-temp-container3 {
    display: none;
  }

  .quality-button {
    width: 100%;
    font-size: 0.9rem;
  }

  .stats-style {
    display: flex;
    justify-content: center;
  }

  .stats-img {
    display: none;
  }

  .scores-div3 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .scores-contain3 {
    width: 100%;
  }

  h5 {
    font-size: 0.4rem;
  }

  .title-contain3 {
    h4 {
      font-size: 1rem;
    }
    p {
      font-size: 0.9rem;
    }
  } // ends .title-contain3

  .icon-contain {
    width: 48%;
    img {
      width: 75%;
    }
  } // ends .icon-contain

  .icon-score-contain {
    width: 100%;
  }

  .number-contain {
    width: 48%;
    h5 {
      font-size: 0.8rem;
    }
    p {
      font-size: 2rem;
    }
  } // ends .number-contain

  .number-style25-2 {
    font-size: 2rem;
  }

  .number-style50-2 {
    font-size: 2rem;
  }

  .number-style75-2 {
    font-size: 2rem;
  }

  .number-style100-2 {
    font-size: 2rem;
  }

  .stats-par {
    font-size: 1.2rem;
    padding: 0;
  }
} // this ends @meda styling for 3 cities

// STYLING 800px FOR 1 CITY
@media screen and (max-width: 800px) {
  .stats-div {
    display: flex;
    height: 250px;
    width: 100%;
  }

  .button-div {
    display: flex;
    justify-content: center;
  }

  .city-overview-border {
    display: flex;
    justify-content: center;
  }

  .main-contain {
    display: flex;
    flex-direction: column;
  }

  .stats-div-readjusted {
    width: 100%;
  }

  .scores-div {
    width: 100%;
  }

  .number-contain {
    h4 {
      font-size: 2rem;
    }
  }

  .scores-container {
    display: flex;
    flex-wrap: wrap;
  }

  .scores-contain {
    width: 48%;
  }
} // ends 800px mobile styling for 1 CITY SELECTED

// MEDIA 800PX FOR 2 CITIES
@media screen and (max-width: 800px) {
  .button-weather-contain {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .title-container2 {
    display: flex;
    flex-direction: column;
    text-align: center;

    p {
      font-size: 1rem;
    }
  }

  .button-div2 {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .stats-style2 {
    width: 90%;
  }

  .scores-div2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .title-contain2 {
    text-align: center;
  }

  .icon-score-contain {
    justify-content: center;
  }

  .icon-contain {
    text-align: right;
  }

  .number-contain {
    text-align: center;
    text-align: left;
  }

  .scores-contain2 {
    width: 100%;
  }

  .walking-img2 {
    width: 50%;
  }

  .number-style25-3 {
    font-size: 50px;
  }

  .number-style50-3 {
    font-size: 50px;
  }

  .number-style75-3 {
    font-size: 50px;
  }

  .number-style100-3 {
    font-size: 50px;
  }
} // this ends media for 800px for 2 cities selected

// MEDIA 800PX FOR 3 CITIES
@media screen and (max-width: 800px) {
  .city-overview-border3 {
    height: 75px;

    .title-contain3 {
      font-size: 1rem;
    }
  }

  .avg-temp-container {
    display: none;
  }
  .avg-temp-container2 {
    display: none;
  }
  .avg-temp-container3 {
    display: none;
  }

  .quality-button {
    width: 99%;
    font-size: 0.9rem;
  }

  .stats-img {
    display: none;
  }

  .stats-style {
    text-align: center;
  }

  .scores-div3 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .scores-contain3 {
    width: 100%;
    margin-top: 5px;
  }

  h5 {
    font-size: 0.4rem;
  }

  .title-contain3 {
    h4 {
      font-size: 1rem;
    }
    p {
      font-size: 0.9rem;
    }
  } // ends .title-contain3

  .icon-contain {
    width: 48%;

    .walk-img3 {
      width: 60%;
    }

    img {
      width: 65%;
    }
  } // ends .icon-contain

  .icon-score-contain {
    width: 100%;
  }

  .number-contain {
    width: 48%;
    h5 {
      font-size: 1rem;
    }
  } // ends .number-contain

  .number-style25-2 {
    font-size: 2rem;
  }

  .number-style50-2 {
    font-size: 2rem;
  }

  .number-style75-2 {
    font-size: 2rem;
  }

  .number-style100-2 {
    font-size: 2rem;
  }

  .stats-par {
    font-size: 1.2rem;
  }

  .weather-style3 {
    display: flex;
    width: 100%;
    justify-content: center;
  }
} // this ends media for 800px for 3 cities selected

// STYLING 900px FOR 1 CITY
@media screen and (max-width: 900px) {
  .stats-div {
    display: flex;
    height: 250px;
    width: 100%;
  }

  .button-div {
    display: flex;
    justify-content: center;
  }

  .city-overview-border {
    display: flex;
    justify-content: center;
  }

  .main-contain {
    display: flex;
    flex-direction: column;
  }

  .stats-div-readjusted {
    width: 100%;
  }

  .scores-div {
    width: 100%;
  }

  .number-contain {
    h4 {
      font-size: 2rem;
    }
  }

  .scores-container {
    display: flex;
    flex-wrap: wrap;
  }

  .scores-contain {
    width: 48%;
  }
} // ends 900px mobile styling for 1 CITY SELECTED

// MEDIA 900PX FOR 2 CITIES
@media screen and (max-width: 900px) {
  .button-weather-contain {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .title-container2 {
    display: flex;
    flex-direction: column;
    text-align: center;

    p {
      font-size: 1rem;
    }
  }

  .button-div2 {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .stats-style2 {
    width: 90%;
    display: flex;
    justify-content: center;
  }

  .scores-div2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .title-contain2 {
    text-align: center;
  }

  .icon-score-contain {
    justify-content: center;
  }

  .icon-contain {
    text-align: right;
  }

  .number-contain {
    text-align: center;
    text-align: left;
  }

  .scores-contain2 {
    width: 100%;
  }

  .walking-img2 {
    width: 40%;
  }

  .number-style25-3 {
    font-size: 50px;
  }

  .number-style50-3 {
    font-size: 50px;
  }

  .number-style75-3 {
    font-size: 50px;
  }

  .number-style100-3 {
    font-size: 50px;
  }
} // this ends media for 900px for 2 cities selected

// MEDIA 900PX FOR 3 CITIES
@media screen and (max-width: 900px) {
  .city-overview-border3 {
    height: 75px;

    .title-contain3 {
      font-size: 1rem;
    }
  }

  .avg-temp-container {
    display: none;
  }
  .avg-temp-container2 {
    display: none;
  }
  .avg-temp-container3 {
    display: none;
  }

  .quality-button {
    width: 99%;
    font-size: 0.9rem;
  }

  .stats-img {
    display: none;
  }

  .stats-style {
    text-align: center;
  }

  .scores-div3 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .scores-contain3 {
    width: 100%;
    margin-top: 5px;
  }

  h5 {
    font-size: 0.4rem;
  }

  .title-contain3 {
    h4 {
      font-size: 1rem;
    }
    p {
      font-size: 0.9rem;
    }
  } // ends .title-contain3

  .icon-contain {
    width: 48%;

    .walk-img3 {
      width: 40%;
    }

    img {
      width: 50%;
    }
  } // ends .icon-contain

  .icon-score-contain {
    width: 100%;
  }

  .number-contain {
    width: 48%;
    h5 {
      font-size: 1rem;
    }
  } // ends .number-contain

  .number-style25-2 {
    font-size: 2rem;
  }

  .number-style50-2 {
    font-size: 2rem;
  }

  .number-style75-2 {
    font-size: 2rem;
  }

  .number-style100-2 {
    font-size: 2rem;
  }

  .stats-par {
    font-size: 1.2rem;
  }

  .weather-style3 {
    display: flex;
    width: 100%;
    justify-content: center;
  }
} // this ends media for 900px for 3 cities selected

// STYLING 1300px FOR 1 CITY
@media screen and (max-width: 1300px) {
  .stats-div {
    display: flex;
    width: 100%;
  }

  .weather-contain1 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .stats-div-readjusted {
    display: flex;
    justify-content: center;
  }

  .button-div {
    display: flex;
    justify-content: center;
  }

  .city-overview-border {
    display: flex;
    justify-content: center;
  }

  .main-contain {
    display: flex;
    flex-direction: column;
  }

  .stats-div-readjusted {
    width: 100%;
  }

  .scores-div {
    width: 100%;
  }

  .number-contain {
    h4 {
      font-size: 2rem;
    }
  }

  .scores-container {
    display: flex;
    flex-wrap: wrap;
  }

  .scores-contain {
    width: 48%;
  }
} // ends 1300px mobile styling for 1 CITY SELECTED

// MEDIA 1300PX FOR 2 CITIES
@media screen and (max-width: 1300px) {
  .button-weather-contain {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .title-container2 {
    display: flex;
    flex-direction: column;
    text-align: center;

    p {
      font-size: 1rem;
    }
  }

  .button-div2 {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .stats-style2 {
    width: 90%;
    display: flex;
    justify-content: center;
    img {
      display: none;
    }
  }

  .scores-div2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .title-contain2 {
    text-align: center;
  }

  .icon-score-contain {
    justify-content: center;
  }

  .icon-contain {
    text-align: right;
    display: flex;
    justify-content: center;

    img {
      width: 30%;
    }

    .bike-image {
      width: 45%;
    }
  }

  .number-contain {
    text-align: left;
  }

  .scores-contain2 {
    width: 100%;
  }

  .walking-img2 {
    width: 40%;
  }

  .number-style25-3 {
    font-size: 50px;
  }

  .number-style50-3 {
    font-size: 50px;
  }

  .number-style75-3 {
    font-size: 50px;
  }

  .number-style100-3 {
    font-size: 50px;
  }
} // this ends media for 1300px for 2 cities selected
